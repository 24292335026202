
let Region = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * ��ȡ������Ϣ
     * @param {any} success
     * @param {any} error
     */
    this.Regions = function (regionId, success, error) {
        var url = ServiceBaseAddress + '/api/Region';
        if (regionId) {
            url += '/' + regionId;
        }
        url += '/Childrens';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getRegions = function (regionId, success, error) {
        var url = ServiceBaseAddress + '/api/Region/' + regionId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.Detial = function (regionId, success, error) {
        var url = ServiceBaseAddress + '/api/Region/' + regionId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ��������
     * @param {any} regionName ��������
     * @param {any} fullName ����ȫ��
     * @param {any} regionCode �������
     * @param {any} postCode ������������
     * @param {any} regionLevel ���򼶱� 1��ʡ  2����  3������  4�����򡢽ֵ�  5������
     * @param {any} success
     * @param {any} error
     */
    this.Add = function (regionName, fullName, regionCode, postCode, regionLevel, parentId, success, error) {
        var url = ServiceBaseAddress + '/api/Region';
        var param = {
            regionName: regionName,
            fullName: fullName,
            regionCode: regionCode,
            postCode: postCode,
            regionLevel: regionLevel,
            parentId: parentId
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * �༭������Ϣ
     * @param {any} regionId
     * @param {any} regionName ��������
     * @param {any} fullName ����ȫ��
     * @param {any} regionCode �������
     * @param {any} postCode ������������
     * @param {any} regionLevel ���򼶱� 1��ʡ  2����  3������  4�����򡢽ֵ�  5������
     * @param {any} success
     * @param {any} error
     */
    this.Edit = function (regionId, regionName, fullName, regionCode, postCode, regionLevel, parentId, success, error) {
        var url = ServiceBaseAddress + '/api/Region';
        var param = {
            id: regionId,
            regionName: regionName,
            fullName: fullName,
            regionCode: regionCode,
            postCode: postCode,
            regionLevel: regionLevel,
            parentId: parentId
        };
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * �Ƴ�����
     * @param {any} regionId
     * @param {any} success
     * @param {any} error
     */
    this.Remove = function (regionId, success, error) {
        var url = ServiceBaseAddress + '/api/Region/' + regionId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Region }